<ng-container *ngIf="isProgressBar">
  <kendo-progressbar [indeterminate]="true"></kendo-progressbar>
</ng-container>
<ng-container *ngIf="!isProgressBar">
  <div class="loader-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-container>

